import { CSpinner } from '@coreui/react'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from 'react-router-dom'
import { Loading } from './components/loading'
import { PrivateRoute } from './components/private-route'
import './scss/style.scss'
import { history } from './utilities/history'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/Login'))
const Logout = React.lazy(() => import('./views/auth/Logout'))
const Page404 = React.lazy(() => import('./views/errors/Page404'))
const Page500 = React.lazy(() => import('./views/errors/Page500'))

export const App = () => {

  const loading = useSelector(state => state.panel.loading);

  return (
    <>
      <HistoryRouter history={history}>
        <Suspense fallback={<div className='center'><CSpinner /></div>}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/logout" name="Login Page" element={<Logout />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<PrivateRoute component={DefaultLayout} />} />
          </Routes>
        </Suspense>
      </HistoryRouter>
      <Loading fullscreen={true} loading={loading > 0} />
    </>
  )
}

export default App
